import Vue from 'vue';
import App from './App.vue';
import VueMask from 'v-mask';

require('@/assets/main.css');

Vue.config.productionTip = false;
Vue.use(VueMask);

new Vue({
  render: (h) => h(App),
}).$mount('#app');
