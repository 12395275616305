<template>
  <div id="app">
    <div class="container">
      <form @submit.prevent="">
        <!--<div v-show="!isMobile" class="desktop">-->
        <div class="desktop">
          <div class="overlay">
            <!--<img class="logo-top" src="@/assets/my_service_rgs.svg" alt="" />
            <img class="cover" src="@/assets/desktop.svg" alt="" />-->
            <div class="title" style="position: relative;">
              <h2>
                Получите ссылку для оплаты
              </h2>
            </div>
           <!-- <p class="label">Выберите тип</p>
              <CustomSelect
      :options="['Самоосмотр с документами', 'Самоосмотр', 'Запрос дополнительных документов']"
      :default="'Самоосмотр'"
      class="select"
      @input="displayToKey($event)"
      v-mask="'^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'"
      :disabled="!isDisabled"
    />-->
              <p class="label">Введите email клиента<span class="req">*</span></p>
              <input
                class="inputText"
                type="text"
                placeholder="example@domain.com"
                v-model="email"
                required
              />
              <p class="label">Введите номер заказа<span class="req">*</span></p>
              <input
                class="inputText"
                type="text"
                v-model="orderNumber"
                required
              />
              <div class="services">
                <div v-for="(item, idx) in services" class="service">
                  <div class="name">
                    <p class="label">Введите наименование услуги</p>
                    <input
                      class="inputText"
                      type="text"
                      v-model="services[idx]['service']"
                    />
                  </div>
                  <div class="count">
                    <p class="label">Количество</p>
                    <input
                      class="inputText"
                      type="text"
                      v-model="services[idx]['count']"
                    />
                  </div>
                  <div class="price">
                    <p class="label">Стоимость услуги, руб.</p>
                    <input
                      class="inputText"
                      type="text"
                      v-model="services[idx]['price']"
                    />
                  </div>
                </div>
              </div>
              <p class="left add"><a @click="addService()">Добавить услугу</a></p>
              <p class=total>Сумма счета: {{total}} руб.</p>
              <button
          @click="generate()"
          class="nextStep"
        >
Получить
              </button>
              <div v-show="isGenerated" class="whitebox">
                  <p class="title">{{ link }}</p>
                  <img src="@/assets/copy.svg" @click.stop.prevent="copyUrl" />
                </div>
                <input type="hidden" id="copy-url" :value="link">
          </div>
        </div>

      <!--  <transition name="fade">
          <div v-show="step === 10" class="step custom damages">
            <div class="title" style="position: relative;">
              <h2 class="left">
                Фото повреждений
              </h2>
            </div>
            <div class="overlay">
              <!--<p class="label">Название повреждённой детали</p>
            <input class="inputText" type="text" placeholder="Бампер" />-->
        <!--      <input
                hidden
                type="file"
                id="files7"
                ref="files7"
                multiple
                capture="environment"
                accept="image/*"
                v-on:change="handleFilesUpload7()"
              />
              <input
                hidden
                type="file"
                id="files8"
                ref="files8"
                multiple
                capture="environment"
                accept="image/*"
                v-on:change="handleFilesUpload8()"
              />
              <div
                v-for="(damage, index) in damagesMain"
                :key="damage.name"
                class="group"
              >
                <p class="label strong">
                  <strong>Повреждение {{ index + 1 }}</strong>
                </p>
                <p class="label">Фото общего вида повреждённой детали</p>
                <button
                  type="button"
                  :data-label="index"
                  class="addFilesMultiply"
                  @click="addFiles7(index)"
                  v-show="damagesMain[index].length === 0"
                >
                  <img src="@/assets/blank.svg" />
                </button>
                <div
                  v-for="(photo, idx) in damagesMain[index]"
                  class="uploadedFilePreview"
                >
                  <img
                    src="@/assets/close.svg"
                    class="delete"
                    @click="deletePhoto7(index, idx)"
                  />
                  <!--<span>{{ photo.name }}</span>-->
        <!--   <img class="previewImg" :src="getPreview(photo)" />
                </div>
                <p class="label">Приближённое фото повреждённой детали</p>
                <button
                  type="button"
                  class="addFilesMultiply"
                  @click="addFiles8(index)"
                  v-show="damagesDetail[index].length < 5"
                >
                  <img src="@/assets/blank.svg" />
                </button>
                <div
                  v-for="(photo, idx) in reverseDamagesDetail(index)"
                  class="uploadedFilePreview"
                >
                  <img
                    src="@/assets/close.svg"
                    class="delete"
                    @click="deletePhoto8(index, idx)"
                  />
                  <!--<span>{{ photo.name }}</span>-->
        <!--    <img class="previewImg" :src="getPreview(photo)" />
                </div>
              </div>
              <!--<p>
            Сделайте фото под углом, встав сзади слева от ТС (со стороны
            водителя). Автомобиль должен полностью попадать в кадр
          </p>-->
        <!--      <span class="chooseOtherFile" @click="addDamage"
                >Добавить повреждение</span
              >
              <!--<p class="notice">
              Этот шаг можно пропустить, если повреждений нет
            </p>-->
        <!--     <div class="contact">
                <p>Есть вопрос?</p>
                <a target="_blank" href="https://auto.moi-service.ru/app"
                  >Свяжитесь с автоконсультантом в чате</a
                >
              </div>
            </div>
          </div>
        </transition>-->
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CustomSelect from "./components/CustomSelect.vue";

export default {
  name: "App",
  components: {
    CustomSelect,
  },
  data() {
    return {
      files: "",
      files2: "",
      files3: "",
      files4: "",
      files5: "",
      files6: "",
      files7: [[]],
      files8: [[]],
      step: 0,
      warning: 0,
      buttonStatus: false,
      checked: false,
      number: "",
      numberInput: "",
      damagesMain: [[]],
      damagesDetail: [[]],
      applicationPages: [],
      certificatePages: [],
      passportPages: [],
      registrationPages: [],
      driverLicensePages: [],
      documents: [[]],
      lastButton: "",
      lastButtonDocuments: "",
      loader: false,
      percentCompleted: 0,
      index: false,
      available: [],
      url: window.location.href,
      types : {'Самоосмотр': 'lite', 'Самоосмотр с документами': 'full', 'Запрос дополнительных документов': 'add'},
      type: "",
      link: "",
      price: 0,
      count: 0,
      service: '',
      email: '',
      orderNumber: '',
      services: [{"service": "", "count": "1", "price": "0"}],
    };
  },
  mounted: function() {
    console.log("test");
    this.number = this.getNuber();
    //  this.url = window.location.href;
  },
  computed: {
    isDisabled: function(index) {
      if (this.numberInput.length === 10) {
        return true;
      }
      return false;
    },
    unChecked: function() {
      return !this.checked;
    },
    startProcess: function() {
      if (
        ((this.number && this.number.length === 10) ||
          (this.numberInput && this.numberInput.length === 10)) &&
        !this.unChecked
      ) {
        return true;
      }
      return false;
    },
    isMobile: function() {
      console.log(window.innerWidth);
      if (window.innerWidth <= 500) {
        return true;
      } else {
        return false;
      }
    },
    isGenerated: function() {
      if (this.link.length === 0) {
        return false;
      }
      return true;
    },
    total: function() {
      let total = 0;
      this.services.forEach((element)  =>  {
        //if (element.price.length !== 0 && element.count.length !== 0) {
          total += element.count * element.price;
        //}
      });
      //return total !== 'NaN' ? total : 0;
      return total;
    }
  },
  methods: {
    generate() {
      //this.link = 'https://osmotr.auto.moi-service.ru/'+this.type+'?number='+this.numberInput;
      const self = this;
      let formData = new FormData();
      formData.append("email", this.email);
      formData.append("orderNumber", this.orderNumber);
      formData.append("services", JSON.stringify(this.services));
      //console.log(formData);
      //return false;
      axios
        .post("https://qdr.rn86.ru/rgs/invoices/createInvoice.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          /*onUploadProgress: (progressEvent) => {
            this.percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            //console.log(percentCompleted);
          },*/
        })
        .then(function(res) {
          console.log(res);
          self.link = res.data;
          //next();
          //step++;
          //console.log(this.step);
          //console.log(':');
        })
        .catch(function() {
          console.log("FAILURE!!");
        });
    },
    addService() {
      this.services.push({"service": "", "count": "1", "price": "0"});
      console.log(this.services);
    },
    displayToKey(event) {
      this.type = this.types[event];
      this.link = '';
    },
    copyUrl() {
      let urlToCopy = document.querySelector("#copy-url");
      urlToCopy.setAttribute("type", "text");
      urlToCopy.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        // alert('Testing code was copied ' + msg);
      } catch (err) {
        //  alert('Oops, unable to copy');
      }

      /* unselect the range */
      urlToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    onSubmit() {
      //let step = this.step;
      let next = this.nextStep;
      let formData = new FormData();
      /*for (var i = 0; i < this.files.length; i++) {
        let file = this.files[i];
        formData.append("fileName[" + i + "]", file);
      }
      for (var i = 0; i < this.files2.length; i++) {
        let file2 = this.files2[i];
        formData.append("fileName_2[" + i + "]", file2);
      }
      for (var i = 0; i < this.files3.length; i++) {
        let file3 = this.files3[i];
        formData.append("fileName_3[" + i + "]", file3);
      }
      for (var i = 0; i < this.files4.length; i++) {
        let file4 = this.files4[i];
        formData.append("fileName_4[" + i + "]", file4);
      }
      for (var i = 0; i < this.files5.length; i++) {
        let file5 = this.files5[i];
        formData.append("fileName_5[" + i + "]", file5);
      }
      for (var i = 0; i < this.files6.length; i++) {
        let file6 = this.files6[i];
        formData.append("fileName_6[" + i + "]", file6);
      }
      var file7number = 0;
      var file7;
      for (var i = 0; i < this.files7.length; i++) {
        for (var j = 0; j < this.files7[i].length; j++) {
          for (var z = 0; z < this.files7[i][j].length; z++) {
            file7 = this.files7[i][j][z];
            var temp = file7.slice(0, file7.size, file7.type);
            var newFile = new File(
              [temp],
              "7_" + (i + 1) + "_" + (j + 1) + ".jpg",
              {
                type: "image/jpg",
              }
            );
            formData.append("fileName_7[" + file7number + "]", newFile);
            file7number++;
          }
        }
      }
      var file8number = 0;
      var file8;
      for (var i = 0; i < this.files8.length; i++) {
        for (var j = 0; j < this.files8[i].length; j++) {
          for (var z = 0; z < this.files8[i][j].length; z++) {
            file8 = this.files8[i][j][z];
            var temp = file8.slice(0, file8.size, file8.type);
            var newFile = new File(
              [temp],
              "8_" + (i + 1) + "_" + (j + 1) + ".jpg",
              {
                type: "image/jpg",
              }
            );
            formData.append("fileName_8[" + file8number + "]", newFile);
            file8number++;
          }
        }
      }*/

      /*for (var i = 0; i < this.filesApplication.length; i++) {
        let fileApplication = this.filesApplication[i];
        console.log(fileApplication);
        formData.append("fileName_Application[" + i + "]", fileApplication);
      }*/
/*
      var fileApplicationNumber = 0;
      var fileApplication;
      var ext;
      for (var i = 0; i < this.filesApplication.length; i++) {
        for (var j = 0; j < this.filesApplication[i].length; j++) {
          //  for (var z = 0; z < this.filesDriverLicense[i][j].length; z++) {
          fileApplication = this.filesApplication[i][j];
          var temp = fileApplication.slice(
            0,
            fileApplication.size,
            fileApplication.type
          );
          // console.log(temp);
          if (fileApplication.type == "application/pdf") {
            ext = "pdf";
          } else {
            ext = "jpg";
          }
          var newFile = new File(
            [temp],
            //  "driverLicense_" + (i + 1) + "_" + (j + 1) + "." + ext,
            "application_" + i + "." + ext,
            {
              //type: "image/jpg",
              type: temp.type,
            }
          );
          formData.append(
            "fileName_Application[" + fileApplicationNumber + "]",
            newFile
          );
          fileApplicationNumber++;
          //  }
        }
      }
*/
      /*for (var i = 0; i < this.filesCertificate.length; i++) {
        let fileCertificate = this.filesCertificate[i];
        formData.append("fileName_Certificate[" + i + "]", fileCertificate);
      }*/
/*
      var fileCertificateNumber = 0;
      var fileCertificate;
      var ext;
      for (var i = 0; i < this.filesCertificate.length; i++) {
        for (var j = 0; j < this.filesCertificate[i].length; j++) {
          //  for (var z = 0; z < this.filesDriverLicense[i][j].length; z++) {
          fileCertificate = this.filesCertificate[i][j];
          var temp = fileCertificate.slice(
            0,
            fileCertificate.size,
            fileCertificate.type
          );
          // console.log(temp);
          if (fileCertificate.type == "application/pdf") {
            ext = "pdf";
          } else {
            ext = "jpg";
          }
          var newFile = new File(
            [temp],
            //  "driverLicense_" + (i + 1) + "_" + (j + 1) + "." + ext,
            "certificate_" + i + "." + ext,
            {
              //type: "image/jpg",
              type: temp.type,
            }
          );
          formData.append(
            "fileName_Certificate[" + fileCertificateNumber + "]",
            newFile
          );
          fileCertificateNumber++;
          //  }
        }
      }
*/
      /*for (var i = 0; i < this.filesPassport.length; i++) {
        let filePassport = this.filesPassport[i];
        formData.append("fileName_Passport[" + i + "]", filePassport);
      }*/
/*
      var filePassportNumber = 0;
      var filePassport;
      var ext;
      for (var i = 0; i < this.filesPassport.length; i++) {
        for (var j = 0; j < this.filesPassport[i].length; j++) {
          //  for (var z = 0; z < this.filesDriverLicense[i][j].length; z++) {
          filePassport = this.filesPassport[i][j];
          var temp = filePassport.slice(
            0,
            filePassport.size,
            filePassport.type
          );
          // console.log(temp);
          if (filePassport.type == "application/pdf") {
            ext = "pdf";
          } else {
            ext = "jpg";
          }
          var newFile = new File(
            [temp],
            //  "driverLicense_" + (i + 1) + "_" + (j + 1) + "." + ext,
            "passport_" + i + "." + ext,
            {
              //type: "image/jpg",
              type: temp.type,
            }
          );
          formData.append(
            "fileName_Passport[" + filePassportNumber + "]",
            newFile
          );
          filePassportNumber++;
          //  }
        }
      }
*/
      /*for (var i = 0; i < this.filesRegistration.length; i++) {
        let fileRegistration = this.filesRegistration[i];
        formData.append("fileName_Registration[" + i + "]", fileRegistration);
      }*/
/*
      var fileRegistrationNumber = 0;
      var fileRegistration;
      var ext;
      for (var i = 0; i < this.filesRegistration.length; i++) {
        for (var j = 0; j < this.filesRegistration[i].length; j++) {
          //  for (var z = 0; z < this.filesDriverLicense[i][j].length; z++) {
          fileRegistration = this.filesRegistration[i][j];
          var temp = fileRegistration.slice(
            0,
            fileRegistration.size,
            fileRegistration.type
          );
          // console.log(temp);
          if (fileRegistration.type == "application/pdf") {
            ext = "pdf";
          } else {
            ext = "jpg";
          }
          var newFile = new File(
            [temp],
            //  "driverLicense_" + (i + 1) + "_" + (j + 1) + "." + ext,
            "registration_" + i + "." + ext,
            {
              //type: "image/jpg",
              type: temp.type,
            }
          );
          formData.append(
            "fileName_Registration[" + fileRegistrationNumber + "]",
            newFile
          );
          fileRegistrationNumber++;
          //  }
        }
      }
*/
      /*for (var i = 0; i < this.filesDriverLicense.length; i++) {
        let fileDriverLicense = this.filesDriverLicense[i];
        formData.append("fileName_DriverLicense[" + i + "]", fileDriverLicense);
      }*/
/*
      var fileDriverLicenseNumber = 0;
      var fileDriverLicense;
      var ext;
      for (var i = 0; i < this.filesDriverLicense.length; i++) {
        for (var j = 0; j < this.filesDriverLicense[i].length; j++) {
          //  for (var z = 0; z < this.filesDriverLicense[i][j].length; z++) {
          fileDriverLicense = this.filesDriverLicense[i][j];
          var temp = fileDriverLicense.slice(
            0,
            fileDriverLicense.size,
            fileDriverLicense.type
          );
          console.log(temp);
          if (fileDriverLicense.type == "application/pdf") {
            ext = "pdf";
          } else {
            ext = "jpg";
          }
          var newFile = new File(
            [temp],
            //  "driverLicense_" + (i + 1) + "_" + (j + 1) + "." + ext,
            "driverLicense_" + i + "." + ext,
            {
              //type: "image/jpg",
              type: temp.type,
            }
          );
          formData.append(
            "fileName_DriverLicense[" + fileDriverLicenseNumber + "]",
            newFile
          );
          fileDriverLicenseNumber++;
          //  }
        }
      }

      var fileOtherNumber = 0;
      var fileOther;
      var ext;
      for (var i = 0; i < this.filesOther.length; i++) {
        for (var j = 0; j < this.filesOther[i].length; j++) {
          for (var z = 0; z < this.filesOther[i][j].length; z++) {
            fileOther = this.filesOther[i][j][z];
            var temp = fileOther.slice(0, fileOther.size, fileOther.type);
            //console.log(temp);
            if (fileOther.type == "application/pdf") {
              ext = "pdf";
            } else {
              ext = "jpg";
            }
            var newFile = new File(
              [temp],
              "other_" + (i + 1) + "_" + (j + 1) + "." + ext,
              {
                //type: "image/jpg",
                type: temp.type,
              }
            );
            formData.append("fileName_Other[" + fileOtherNumber + "]", newFile);
            fileOtherNumber++;
          }
        }
      }*/
      // console.log('number: ' + this.number);
      //  console.log('numberInput: ' + this.numberInput);
      //  return false;
      if (this.number == "" || this.number == null) {
        formData.append("number", this.numberInput);
      } else {
        formData.append("number", this.number);
      }
      this.buttonStatus = false;
      this.loader = true;
      for (var value of formData.values()) {
        console.log(value);
      }
      //console.log(formData);
      //return false;
      axios
        .post("https://lrvl.rn86.ru/api/damages", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            this.percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            //console.log(percentCompleted);
          },
        })
        .then(function() {
          console.log("SUCCESS!!");
          next();
          //step++;
          //console.log(this.step);
          //console.log(':');
        })
        .catch(function() {
          console.log("FAILURE!!");
        });
      //console.log(step);
    },
    getPreview(file) {
      return URL.createObjectURL(file);
    },
    reverseDamagesDetail(index) {
      return this.damagesDetail[index].slice().reverse();
    },
    reverseDocuments(index) {
      return this.documents[index].slice().reverse();
    },
    nextStep() {
      this.step++;
      this.buttonStatus = false;
      this.number = this.getNuber();
      console.log(this.available);
    },
    prevStep() {
      this.step--;
      this.buttonStatus = false;
      this.number = this.getNuber();
    },
    setWarning() {
      this.warning = 1;
      console.log(this.warning);
    },
    addDamage() {
      this.damagesMain.push([]);
      this.damagesDetail.push([]);
      this.files7.push([]);
      this.files8.push([]);
    },
    addDocuments() {
      this.documents.push([]);
      this.filesOther.push([]);
    },
    addFiles() {
      this.$refs.files.click();
    },
    addFiles2() {
      this.$refs.files2.click();
    },
    addFiles3() {
      this.$refs.files3.click();
    },
    addFiles4() {
      this.$refs.files4.click();
    },
    addFiles5() {
      this.$refs.files5.click();
    },
    addFiles6() {
      this.$refs.files6.click();
    },
    addFiles7(index) {
      this.$refs.files7.click();
      this.lastButton = index;
    },
    addFiles8(index) {
      this.$refs.files8.click();
      this.lastButton = index;
    },
    addFilesApplication(index) {
      this.$refs.filesApplication.click();
    },
    addFilesCertificate(index) {
      this.$refs.filesCertificate.click();
    },
    addFilesPassport(index) {
      this.$refs.filesPassport.click();
    },
    addFilesRegistration(index) {
      this.$refs.filesRegistration.click();
    },
    addFilesDriverLicense(index) {
      this.$refs.filesDriverLicense.click();
    },
    addFilesOther(index) {
      this.$refs.filesOther.click();
      this.lastButtonDocuments = index;
    },
    handleFilesUpload() {
      this.files = this.$refs.files.files;
      if (!this.available.includes(this.step)) {
        this.available.push(this.step);
      }
      console.log(this.available);
      console.log(this.available.includes(this.step));
      this.buttonStatus = true;
    },
    handleFilesUpload2() {
      this.files2 = this.$refs.files2.files;
      if (!this.available.includes(this.step)) {
        this.available.push(this.step);
      }
      this.buttonStatus = true;
    },
    handleFilesUpload3() {
      this.files3 = this.$refs.files3.files;
      if (!this.available.includes(this.step)) {
        this.available.push(this.step);
      }
      this.buttonStatus = true;
    },
    handleFilesUpload4() {
      this.files4 = this.$refs.files4.files;
      if (!this.available.includes(this.step)) {
        this.available.push(this.step);
      }
      this.buttonStatus = true;
    },
    handleFilesUpload5() {
      this.files5 = this.$refs.files5.files;
      if (!this.available.includes(this.step)) {
        this.available.push(this.step);
      }
      this.buttonStatus = true;
    },
    handleFilesUpload6() {
      this.files6 = this.$refs.files6.files;
      if (!this.available.includes(this.step)) {
        this.available.push(this.step);
      }
      this.buttonStatus = true;
    },
    handleFilesUpload7() {
      /*if (this.files7.length > 0) {
        this.files7.add(this.$refs.files7.files);
      } else {
        this.files7 = this.$refs.files7.files;
      }*/
      //console.log(this.$refs.files7.files);
      console.log(this.lastButton);
      console.log(this.files7[this.lastButton]);
      this.files7[this.lastButton].push(this.$refs.files7.files);
      this.buttonStatus = true;
      this.$refs.files7.files.forEach((element) => {
        this.damagesMain[this.lastButton].push(element);
      });
      if (
        this.damagesDetail[this.lastButton].length !== 0 &&
        this.damagesMain[this.lastButton].length !== 0
      ) {
        if (!this.available.includes(this.step)) {
          this.available.push(this.step);
        }
      } else {
        const index = this.available.indexOf(this.step);
        if (index > -1) {
          this.available.splice(index, 1);
        }
      }
      console.log(this.damages);
    },
    handleFilesUpload8() {
      //this.files8 = this.$refs.files8.files;
      this.files8[this.lastButton].push(this.$refs.files8.files);
      this.buttonStatus = true;
      this.$refs.files8.files.forEach((element) => {
        this.damagesDetail[this.lastButton].push(element);
      });
      //this.damages[this.lastButton].push(this.$refs.files8.files);
      if (
        this.damagesDetail[this.lastButton].length !== 0 &&
        this.damagesMain[this.lastButton].length !== 0
      ) {
        if (!this.available.includes(this.step)) {
          this.available.push(this.step);
        }
      } else {
        const index = this.available.indexOf(this.step);
        if (index > -1) {
          this.available.splice(index, 1);
        }
      }
      console.log(this.damages);
    },
    handleFilesUploadApplication() {
      //this.files8 = this.$refs.files8.files;
      this.filesApplication.push(this.$refs.filesApplication.files);
      this.buttonStatus = true;
      this.$refs.filesApplication.files.forEach((element) => {
        this.applicationPages.push(element);
      });
      if (!this.available.includes(this.step)) {
        this.available.push(this.step);
      }
    },
    handleFilesUploadCertificate() {
      //this.files8 = this.$refs.files8.files;
      this.filesCertificate.push(this.$refs.filesCertificate.files);
      this.buttonStatus = true;
      this.$refs.filesCertificate.files.forEach((element) => {
        this.certificatePages.push(element);
      });
      if (
        this.certificatePages.length !== 0 &&
        this.passportPages.length !== 0 &&
        this.registrationPages.length !== 0
      ) {
        if (!this.available.includes(this.step)) {
          this.available.push(this.step);
        }
      }
    },
    handleFilesUploadPassport() {
      //this.files8 = this.$refs.files8.files;
      this.filesPassport.push(this.$refs.filesPassport.files);
      this.buttonStatus = true;
      this.$refs.filesPassport.files.forEach((element) => {
        this.passportPages.push(element);
      });
      if (
        this.certificatePages.length !== 0 &&
        this.passportPages.length !== 0 &&
        this.registrationPages.length !== 0
      ) {
        if (!this.available.includes(this.step)) {
          this.available.push(this.step);
        }
      }
    },
    handleFilesUploadRegistration() {
      //this.files8 = this.$refs.files8.files;
      this.filesRegistration.push(this.$refs.filesRegistration.files);
      this.buttonStatus = true;
      this.$refs.filesRegistration.files.forEach((element) => {
        this.registrationPages.push(element);
      });
      if (
        this.certificatePages.length !== 0 &&
        this.passportPages.length !== 0 &&
        this.registrationPages.length !== 0
      ) {
        if (!this.available.includes(this.step)) {
          this.available.push(this.step);
        }
      }
    },
    handleFilesUploadDriverLicense() {
      //this.files8 = this.$refs.files8.files;
      this.filesDriverLicense.push(this.$refs.filesDriverLicense.files);
      this.buttonStatus = true;
      this.$refs.filesDriverLicense.files.forEach((element) => {
        this.driverLicensePages.push(element);
      });
    },
    handleFilesUploadOther() {
      //this.files8 = this.$refs.files8.files;
      this.filesOther[this.lastButtonDocuments].push(
        this.$refs.filesOther.files
      );
      this.buttonStatus = true;
      this.$refs.filesOther.files.forEach((element) => {
        this.documents[this.lastButtonDocuments].push(element);
      });
      if (this.filesOther.length !== 0) {
        if (!this.available.includes(this.step)) {
          this.available.push(this.step);
        }
      }
      //this.damages[this.lastButton].push(this.$refs.files8.files);

      console.log(this.damages);
    },
    getNuber() {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get("number");
    },
    deletePhoto7(index, idx) {
      //console.log(this.damages[index]);
      this.damagesMain[index].splice(idx, 1);
      this.files7[index].splice(idx, 1);
      if (
        (this.damagesDetail[this.lastButton].length !== 0 &&
          this.damagesMain[this.lastButton].length !== 0) ||
        (this.damagesDetail[this.lastButton].length === 0 &&
          this.damagesMain[this.lastButton].length === 0)
      ) {
        if (!this.available.includes(this.step)) {
          this.available.push(this.step);
        }
      } else {
        const index = this.available.indexOf(this.step);
        if (index > -1) {
          this.available.splice(index, 1);
        }
      }
      //console.log(this.$refs.files7);
    },
    deletePhoto8(index, idx) {
      //console.log(this.damages[index]);
      this.damagesDetail[index].splice(idx, 1);
      this.files8[index].splice(idx, 1);
      if (
        (this.damagesDetail[this.lastButton].length !== 0 &&
          this.damagesMain[this.lastButton].length !== 0) ||
        (this.damagesDetail[this.lastButton].length === 0 &&
          this.damagesMain[this.lastButton].length === 0)
      ) {
        if (!this.available.includes(this.step)) {
          this.available.push(this.step);
        }
      } else {
        const index = this.available.indexOf(this.step);
        if (index > -1) {
          this.available.splice(index, 1);
        }
      }
    },
    deletePhotoApplication(idx) {
      this.applicationPages.splice(idx, 1);
      this.filesApplication.splice(idx, 1);
      if (this.applicationPages.length === 0) {
        const index = this.available.indexOf(this.step);
        if (index > -1) {
          this.available.splice(index, 1);
        }
      }
    },
    deletePhotoCertificate(idx) {
      this.certificatePages.splice(idx, 1);
      this.filesCertificate.splice(idx, 1);
      if (this.certificatePages.length === 0) {
        const index = this.available.indexOf(this.step);
        if (index > -1) {
          this.available.splice(index, 1);
        }
      }
    },
    deletePhotoPassport(idx) {
      this.passportPages.splice(idx, 1);
      this.filesPassport.splice(idx, 1);
      if (this.passportPages.length === 0) {
        const index = this.available.indexOf(this.step);
        if (index > -1) {
          this.available.splice(index, 1);
        }
      }
    },
    deletePhotoRegistration(idx) {
      this.registrationPages.splice(idx, 1);
      this.filesRegistration.splice(idx, 1);
      if (this.registrationPages.length === 0) {
        const index = this.available.indexOf(this.step);
        if (index > -1) {
          this.available.splice(index, 1);
        }
      }
    },
    deletePhotoDriverLicense(idx) {
      this.driverLicensePages.splice(idx, 1);
      this.filesDriverLicense.splice(idx, 1);
      if (this.driverLicensePages.length === 0) {
        const index = this.available.indexOf(this.step);
        if (index > -1) {
          this.available.splice(index, 1);
        }
      }
    },
    deletePhotoOther(index, idx) {
      //console.log(this.damages[index]);
      this.documents[index].splice(idx, 1);
      this.filesOther[index].splice(idx, 1);
      if (this.documents[this.lastButtonDocuments].length !== 0) {
        if (!this.available.includes(this.step)) {
          this.available.push(this.step);
        }
      } else {
        const index = this.available.indexOf(this.step);
        if (index > -1) {
          this.available.splice(index, 1);
        }
      }
    },
  },
};
</script>

<style>
html {
  background-color: #ebebeb;
}
body {
  margin: 0;
}
a {
  cursor: pointer;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.container {
  max-width: 640px;
  margin: 0 auto;
  /*margin-bottom: 72px;*/
}
.step {
  position: relative;
}
.splash {
  /*background: #ececec;*/
}
.logo-top {
  float: left;
  margin: 26px 0 64px;
}
/*.logo-bottom {
      position: fixed;
    left: calc(50% - 90px);
    bottom: 120px;
}*/
div.title {
  position: absolute;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}
.splash h2 {
  font-weight: 400 !important;
  font-size: 24px !important;
  line-height: 36px !important;
}
.cover {
  width: 100%;
  width: 300px;
  display: none;
}
.overlay {
  padding: 0px 20px;
}
h2 {
  font-family: MyService;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #000;
}
.white {
  color: #fff;
}
.left {
  text-align: left;
}
.warningScreen p.left {
  margin: 0 0 20px;
}
form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
input {
  width: 100%;
  box-sizing: border-box;
}
p {
  margin: 20px 0;
  font-size: 14px;
  line-height: 24px;
  color: #a59494;
  font-family: Suisse;
  font-weight: 400;
}
.whitebox {
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  padding: 24px;
  border-radius: 24px;
  margin-bottom: 16px;
}
.whitebox p {
  margin: 0;
}
.whitebox .title {
  font-family: "MyService";
  font-weight: 500;
  font-size: 16px;
  color: #000;
}
.whitebox .subtitle {
  line-height: 20px;
}
.whitebox .button {
  padding-top: 24px;
}
.whitebox .button a {
  padding: 9px 16px;
  color: #fff;
  font-family: "MyService";
  font-size: 16px;
  border-radius: 24px;
  font-weight: 400;
}
.red {
  background: #f0aa14;
}
.green {
  background: #28c387;
}
p.notice {
  background: #f1f0f0;
  border-radius: 12px;
  /*margin-top: 4px;*/
  margin: 4px 0 0;
  padding: 12px 20px;
  color: #625050;
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
}
p.label {
  text-align: left;
  margin-bottom: 8px;
  font-size: 16px;
  clear: both;
}
p.label.strong {
  line-height: 32px;
  font-family: MyService;
  font-weight: 700;
  color: #625050;
}
p.label.strong + p.label {
  margin-top: 0;
}
.contact {
  line-height: 24px;
  display: inline-block;
  font-size: 14px;
  color: #928585;
  font-weight: 400;
  width: 100%;
  /*font-family: "MyService";
  text-decoration: underline;*/
}
.splash .contact {
  text-align: left;
  float: left;
  margin-top: 24px;
}
.damages .contact {
  margin-top: 24px;
  clear: both;
}
.contact p {
  margin: 0;
}
.inputText {
  width: 100%;
  max-width: 640px;
  height: 48px;
  border-radius: 8px;
  border: 1px solid #c8c2c2;
  padding: 12px 16px;
  font-size: 16px;
  line-height: 24px;
  font-family: Suisse;
  color: #928585;
  /*margin-bottom: 24px;*/
}
.inputText::placeholder {
  color: #c8c2c2;
}
.addFiles {
  width: 100%;
  max-width: 640px;
  height: 64px;
  background: #f0aa14;
  border-radius: 35px;
  margin: 20px auto 0;
  border: none;
  color: #fff;
  font-family: MyService;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  cursor: pointer;
}
.addFilesMultiply {
  padding: 0;
  background: none;
  border: none;
  display: block;
  float: left;
  margin-right: 12px;
  margin-bottom: 12px;
}
.uploadedFile {
  background: #f1f0f0;
  border-radius: 16px;
  padding: 20px;
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;
}
.uploadedFile span {
  line-height: 40px;
  text-transform: uppercase;
  color: #1b0000;
  font-size: 16px;
}
.uploadedFile span:first-child {
  line-height: 10px;
}
.uploadedFile span:nth-child(3) {
  float: right;
}
.uploadedFile span:nth-child(3) img {
  vertical-align: middle;
  margin-right: 0;
}
.uploadedFile img {
  margin-right: 20px;
}
.chooseOtherFile {
  color: #f0aa14;
  text-align: center;
  width: 100%;
  cursor: pointer;
  line-height: 48px;
  background: #fcf1f2;
  border-radius: 24px;
  margin-top: 4px;
  margin-bottom: 24px;
}
.damages .chooseOtherFile {
  margin-bottom: 0;
}
.uploadedFilePreview {
  width: 84px;
  height: 120px;
  border-radius: 8px;
  display: block;
  float: left;
  margin-right: 12px;
  background: url(./assets/photo_icon_grey.svg) center 30px no-repeat, #edebeb;
  margin-bottom: 12px;
}
.uploadedFilePreview span {
  font-size: 12px;
  line-height: 28px;
  font-weight: 400;
  font-family: Suisse;
  color: #625050;
  text-transform: uppercase;
  margin: 20px 0 0;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}
.uploadedFilePreview img.delete {
  margin-left: 45px;
  position: absolute;
}
.previewImg {
  width: inherit;
  height: inherit;
  border-radius: 8px;
  margin: 0;
}
.addFiles::before {
  content: url("./assets/icon.svg");
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  padding-right: 12px;
  padding-bottom: 2px;
}
.custom .addFiles {
  margin: 0px auto 24px;
}
.footer {
  position: fixed;
  height: 72px;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0px 2px 4px rgba(27, 0, 0, 0.04),
    0px 4px 32px rgba(27, 0, 0, 0.16);
  padding: 12px 20px;
  box-sizing: border-box;
}
.footer span {
  color: #625050;
}
.nextStep {
  background: #f0aa14;
  border-radius: 24px;
  padding: 12px 32px;
  color: #fff;
  border: none;
  font-size: 14px;
  line-height: 24px;
  float: none;
  font-family: MyService;
  font-weight: 500;
  margin: 36px 0;
  cursor: pointer;
}
.nextStep[disabled] {
  border: 1px solid #f0aa14;
}
.prevStep {
  background: #f8f7f7;
  border-radius: 24px;
  border: none;
  padding: 15px 17px;
  float: left;
  margin-right: 16px;
}
.nextStep:disabled,
.nextStep[disabled] {
  background: #edebeb;
  color: #aca2a2;
}
span {
  line-height: 48px;
  font-size: 14px;
  color: #f0aa14;
  font-weight: 500;
  float: left;
  font-family: MyService;
}
#checkbox {
  margin-right: 12px;
  width: auto;
  margin-top: 4px;
  display: none;
}
label[for="checkbox"] {
  display: inline-block;
  vertical-align: top;
  /*max-width: 308px;*/
  text-align: left;
  font-size: 14px;
  line-height: 24px;
  color: #928585;
  font-family: Suisse;
  font-weight: 400;
  margin-top: 24px;
  padding-left: 28px;
  position: relative;
}
label[for="checkbox"] img {
  position: absolute;
  top: 0;
  left: 0;
}
label a,
a {
  color: #f0aa14;
  text-decoration: none;
}
.phone {
  font-family: MyService;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.group {
  margin-top: 20px;
  /*display: inline-block;*/
  display: block;
}
.loaderButton {
  background: #f0aa14 !important;
  padding: 4px 37px;
}
.loaderButton img {
  width: 40px;
  vertical-align: middle;
}
span.number {
  text-align: left;
  font-size: 16px;
  line-height: 32px;
  color: #1b0000;
  font-family: Suisse;
  font-weight: 500;
}
.warningScreen {
  background: #ececec;
}
.warningScreen > p {
  padding: 0 20px;
}
.warningScreen .overlay {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
}
.warningImage {
  /*width: 45%;*/
  width: 137px;
  height: 130px;
  background-size: contain !important;
  padding: 12px;
  position: relative;
  margin-bottom: 12px;
}
.warningImage p {
  color: #1b0000;
  font-size: 14px;
  line-height: 20px;
  font-family: Suisse;
  text-align: left;
  position: absolute;
  bottom: 8px;
  margin: 0;
}
.warningImage.one {
  background: url(./assets/warning-1.png);
}
.warningImage.two {
  background: url(./assets/warning-2.png);
}
.warningImage.three {
  background: url(./assets/warning-3.png);
}
.step.success {
  display: flex;
  justify-content: center;
  align-items: center;
  /*height: 500px !important;*/

  background: #ebebeb;
  padding-top: 30px;
}
/*
@media screen and (min-height: 660px) {
  .step.success {
    height: 580px !important;
  }
}
@media screen and (min-height: 720px) {
  .step.success {
    height: 660px !important;
  }
}
@media screen and (min-height: 800px) {
  .step.success {
    height: 740px !important;
  }
}*/
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.6s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
/*.scale-slide-enter-active,
.scale-slide-leave-active {
  position: absolute;
  transition: all 0.85s ease;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  display: block;
}*/
.step {
  position: absolute;
  width: 100%;
  padding-bottom: 92px;
}
.step.damages {
  padding-bottom: 90px;
}
.desktop {
  width: 100%;
  /*max-height: 10000px;*/
  position: absolute;
  top: 0;
  left: 0;
 /* background: url(./assets/line.svg) right -110px no-repeat, #ebebeb;*/
  /*padding-bottom: 30px;*/
}
.desktop .overlay {
  max-width: 480px;
  margin: 0 auto;
}
.desktop .logo-top {
  float: none;
  margin: 26px 0 36px;
}
.desktop .whitebox {
  position: relative;
  margin-bottom: 46px;
}
.desktop .whitebox .title {
  margin-right: 36px;
  text-align: left;
  display: inline-block;
}
.desktop .whitebox img {
  position: absolute;
  right: 24px;
  cursor: pointer;
  top: calc(50% - 12px);
}
@media (max-width: 600px) {
  .desktop {
    background-size: 100%;
    background-position: -2px 24px;
  }
}
input{
  outline: none;
}
.services {
  margin-top: 20px;
}
.service {
  clear: both;
  float: left;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 0 10px 10px;
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;
}
.service .name,
.service .count,
.service .price {
  float: left;
}
.service .name {
  width: 100%;
}
.service .count {
  width: 20%;
}
.service .price {
  width: 75%;
  margin-left: 5%;
}
.service .label {
  margin-top: 10px;
}
.total {
  font-size: 18px;
  color: #2e2e2e;
}
.add a {
  font-size: 18px;
}
.req {
    color: #d45054;
    color: #f0aa14;
    vertical-align: super;
    float: none;
    line-height: 6px;
}
</style>